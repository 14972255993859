export function permissionsConfig(group?, tenantId?) {
  return [
    // OWNER
    {
      groups: [`${group || 'group'}`],
      itemName: 'Company Owner',
      srn: 'srn:iam:default:policies:company:owner',
      id: 'owner',
      srnCompare: `:policies:${group || 'company'}:owner`,
      validation: 'ListArtists',
      description: 'Invite and manage user permissions',
      allowedScopes: ['*', 'ListIdentities'],
    },
    // CONTRACT OWNER
    {
      groups: [`${group || 'group'}-resources`, `${group || 'group'}`],
      itemName: 'Catalog Manager',
      id: 'labelCollab',
      srn: 'srn:iam:default:policies:contractowners:collaborator',
      bindedToOwner: true,
      labelGroup: 'Contract Owners',
      description: "Access/edit to artist's profiles and music details",
      allowedScopes: ['*', 'ListArtists'],
    },
    {
      groups: null,
      itemName: "Company's Artist",
      srn: `srn:iam:default:policies:artists:${group || 'group'}:slug`,
      id: 'companyartist',
      srnCompare: `:policies:artists:${group || 'group'}`,
      custom: true,
      description: "Artist's profile",
      allowedScopes: ['*', 'ListArtists'],
    },
    // Company
    {
      groups: [`${group || 'group'}`],
      itemName: 'Projects Admin',
      srn: 'srn:iam:default:policies:spaces:owner',
      id: 'companyAdmin',
      labelGroup: 'Projects',
      bindedToOwner: true,
      description: "Access to company's projects",
      allowedScopes: ['*', 'CreateSpace'],
    },
    {
      groups: [`${group || 'group'}`],
      itemName: 'Projects Collaborator',
      srn: 'srn:iam:default:policies:spaces:collaborator',
      id: 'companyCollab',
      labelGroup: 'Projects',
      description: 'Access to personal and invited projects',
      allowedScopes: ['*', 'CreateSpace'],
    },
    {
      groups: null,
      itemName: 'Specific Company Collaborator',
      srn: `srn:iam:${tenantId || 'sostereo'}:policies:space:collaborator:owner:id`,
      id: 'specificCollab',
      custom: true,
      srnCompare: ':policies:space:collaborator:owner:',
      description: 'Can collaborate within a project',
      allowedScopes: ['*', 'CreateSpace'],
    },
    // Custom Brand Agreement
    {
      groups: [`${group || 'group'}`],
      itemName: 'License Manager',
      srn: `srn:iam:${tenantId || 'sostereo'}:policies:brands:owner`,
      id: 'licenseManager',
      srnCompare: ':policies:brands:owner',
      labelGroup: 'Brand Supervisors',
      bindedToOwner: true,
      description: 'Manage license requests',
      allowedScopes: ['*', 'GetAgreement'],
    },
    {
      groups: [`${group || 'group'}`],
      itemName: 'License Requester',
      srn: `srn:iam:${tenantId || 'sostereo'}:policies:brands:supervisor`,
      id: 'licenseRequest',
      srnCompare: ':policies:brands:supervisor',
      labelGroup: 'Brand Supervisors',
      description: 'Submit license request to company manager',
      allowedScopes: ['*', 'GetAgreement'],
      disallowedScopes: ['GetAgreementCredit'],
    },
    {
      groups: [`${group || 'group'}`],
      itemName: 'Restricted License Requester',
      srn: `srn:iam:${tenantId || 'sostereo'}:policies:brands:restricted`,
      id: 'restrictedLicenseRequest',
      srnCompare: ':policies:brands:restricted',
      labelGroup: 'Brand Supervisors',
      description: 'Submit license request to company manager / Can’t see pricing',
      allowedScopes: ['*', 'GetAgreementCredit'],
    },
    // GROUP
    {
      groups: [`${group || 'group'}`],
      itemName: 'Full Admin Access',
      id: 'admin',
      srn: 'srn:iam:default:policies:admin', // Check if is this one
      description: 'Grants access to be an admin of the whole platform',
      general: true,
      allowedScopes: ['*', 'CreateCompany', 'CreateCompanie'],
    },
    {
      groups: [`${group || 'group'}`],
      itemName: 'Ingestions Coordinator',
      srn: 'srn:iam:default:policies:ingestions:coordinator',
      id: 'ingestionCoordinator',
      description: 'Upload songs to platform',
      general: true,
      allowedScopes: ['*', 'CreateCompany', 'CreateCompanie'],
    },
    {
      groups: [`${group || 'group'}`],
      itemName: 'Editor Manager',
      srn: 'srn:iam:default:policies:editor-manager',
      id: 'editorManager',
      description: 'Rate songs and manage editors',
      general: true,
      allowedScopes: ['*', 'CreateCompany', 'CreateCompanie'],
    },
    {
      groups: [`${group || 'group'}`],
      itemName: 'Editor',
      id: 'editor',
      srn: 'srn:iam:default:policies:editor',
      description: 'Rate songs',
      general: true,
      allowedScopes: ['*', 'CreateEditor'],
    },
    // GENERAL
    {
      groups: null,
      itemName: 'Music Supervisor',
      id: 'musicSupervisor',
      srn: 'srn:iam:default:policies:musicsupervisor',
      description: 'Create/share playlists',
      allowedScopes: [],
      disallowedScopes: [],
    },
    {
      groups: null,
      itemName: 'Artist',
      srn: `srn:iam:${tenantId || 'sostereo'}:policies:artist:id`, // default o sostereo?
      id: 'artist',
      srnCompare: ':policies:artist:',
      custom: true,
      description: "Artis's profile",
      allowedScopes: ['*', 'ListArtists'],
    },
  ];
}
