import { Injectable } from '@angular/core';
import { permissionsConfig } from './config/permissions.config';
import { CommonService } from '../common.service';
import { AuthenticationService, IdentityService } from 'sostereo-services';
import { reduce, clone, uniq } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class PoliciesService {
  // private accessManager = require('sostereo-access-manager');

  constructor(
    private commonService: CommonService,
    private authenticationService: AuthenticationService,
    private identityService: IdentityService,
  ) {}

  // public sd() {
  //   const policy = this.accessManager.Policy.fromJson({
  //     statements: [
  //       {
  //         action: ['GetArtist', 'ListArtist', 'UpdateArtist'],
  //         effect: 'Allow',
  //         resource: [
  //           'srn:sostereo:default:artist:user/123',
  //           'srn:sostereo:default:artist:user/234',
  //           'srn:sostereo:default:artist:user/456'
  //         ]
  //       }
  //     ]
  //   });
  //   const m2 = new this.accessManager.AccessManager([policy], 'sostereo', 'ListArtist');
  //   console.log(m2.serviceActionAllowed());

  //   console.log('Access >>> ', this.accessManager);
  //   return this.accessManager;
  // }

  public getRoles(identity) {
    const identityGroup =
      identity.groups && identity.groups > 0 ? identity.groups[0].replace('-resources', '') : null;
    const identityTenantId = identity.tenantId
      ? identity.tenantId.replace('Identity-', '')
      : 'sostereo';
    return reduce(
      identity.policies,
      (result, srn) => {
        const permMap = permissionsConfig(identityGroup, identityTenantId).filter((perm: any) => {
          if (srn === perm.srn || (perm.srnCompare && srn.includes(perm.srnCompare))) {
            perm.originalSrn = srn;
            return perm;
          }
        });
        if (permMap.length > 0) {
          result.push(permMap[0]);
        }
        return result;
      },
      [],
    );
  }

  public getPermissions(group?: String, tenantId?: String, groupPolicies?: Array<object>) {
    let groupPolicy = [];
    const currentTenant = group && this.authenticationService.getCurrentUser().tenantId === group;
    return reduce(
      permissionsConfig(group, tenantId),
      (result, value) => {
        if (groupPolicies) {
          groupPolicy = groupPolicies.filter((perm: any) => {
            if (value.labelGroup && value.labelGroup === perm.label_group) {
              return perm;
            }
          });
        }
        // When the permissions are retrieved based on groupPolicies
        if (groupPolicies && (groupPolicy.length > 0 || value.id === 'owner')) {
          result.push(value);
        }
        // general logic
        if (
          !value.custom &&
          !groupPolicies &&
          ((group && Array.isArray(value.groups)) || (!group && !value.groups)) &&
          this.commonService.isAllowed(value.allowedScopes, value.disallowedScopes || [])
        ) {
          result.push(value);
        } else if (currentTenant && value.general) {
          // When currentTenant and the general permissions need to be retrieved
          result.push(value);
        }
        return result;
      },
      [],
    );
  }

  public getIdentityPermissions(
    permissions: Array<Object>,
    tenantId?: String,
    groups?: Array<String>,
    labelGroupPolicy?: boolean,
  ) {
    const identityGroup = groups && groups.length > 0 ? groups[0].replace('-resources', '') : null;
    let updatedGroups = clone(groups);
    const updatedPolicies = reduce(
      permissions,
      (result, value: any) => {
        if (value.groups) {
          updatedGroups = value.groups.map((gr) =>
            identityGroup.includes('group') ? identityGroup : gr.replace(/group/g, identityGroup),
          );
          if (
            value.validation &&
            (labelGroupPolicy ||
              this.commonService.isAllowed(
                [value.validation],
                ['CreateCompany', 'CreateCompanie', '*'],
              ))
          ) {
            updatedGroups.push(`${identityGroup}-resources`);
          }
        }
        result.push(
          value.srn
            .replace('tenantId', tenantId || 'default')
            .replace('group', identityGroup || 'group'),
        );
        return result;
      },
      [],
    );
    return { policies: uniq(updatedPolicies), groups: updatedGroups };
  }

  public addPolicyToIdentity(permission, permissionList, identity) {
    let userUpdate;
    let policiesUpdate;
    if (permission.id === 'owner') {
      policiesUpdate = permissionList.filter((perm) => perm.bindedToOwner);
      policiesUpdate.push(permission);
    } else {
      policiesUpdate = [permission];
    }
    userUpdate = this.getIdentityPermissions(
      policiesUpdate,
      identity.tenantId ? identity.tenantId.replace('Identity-', '') : null,
      identity.groups,
    );
    return new Promise((resolve, reject) => {
      this.identityService
        .addPolicyToUser(identity._id, { policies: userUpdate.policies })
        .subscribe(
          (pRes) => {
            resolve({ pRes, acceptedPermissions: policiesUpdate });
          },
          (httpErr) => {
            reject({ httpErr, rejectedPermissions: policiesUpdate });
          },
        );
    });
  }

  public removeIdentityFromGroup(user, companySlug) {
    const updatedUser = {
      groups: user.groups.filter((g) => g !== companySlug && g !== `${companySlug}-resources`),
    };
    return new Promise((resolve, reject) => {
      this.identityService.update(user._id, updatedUser).subscribe({
        next: () => {
          resolve({});
        },
        error: () => {
          reject();
        },
      });
    });
  }

  public removePolicyFromGroup(user, companySlug) {
    const updatedUser = {
      groups: user.groups.filter((g) => g !== companySlug && g !== `${companySlug}-resources`),
    };
    return new Promise((resolve, reject) => {
      this.identityService.update(user._id, updatedUser).subscribe({
        next: () => {
          resolve({});
        },
        error: () => {
          reject();
        },
      });
    });
  }
}
